import type { NitroFetchRequest, $Fetch } from 'nitropack';
import type {
  CompetitionPrize,
  PostResponse,
  ScheduledAndActiveCompetitonsResponse,
  ShortCompetition,
  ShortCompetitionWinner,
  ShortCompetitionWinnersResponse
} from '~/interfaces';

export const shortChallengeRepository = <T>(fetch: $Fetch<T, NitroFetchRequest>) => ({
  async getActiveAndScheduledCompetitions(): Promise<ScheduledAndActiveCompetitonsResponse> {
    return await fetch<ScheduledAndActiveCompetitonsResponse>('/api/short-competition/scheduled');
  },

  async getActiveCompetitions(): Promise<ShortCompetition[]> {
    return await fetch<ShortCompetition[]>('/api/short-competition');
  },

  async getActiveCompetitionsRewards(): Promise<CompetitionPrize[]> {
    return await fetch<CompetitionPrize[]>('/api/short-competition/rewards');
  },

  async getShortCompetitionsWinners(
    page: number = 1,
    limit: number = 20
  ): Promise<ShortCompetitionWinnersResponse> {
    return await fetch<ShortCompetitionWinnersResponse>(
      `/api/short-competition/winners?page=${page}&limit=${limit}`
    );
  },

  async getShortCompetitionsPosts(
    cursor: string = '',
    shortCompetitionId?: number
  ): Promise<PostResponse> {
    return await fetch<PostResponse>(
      `/api/short-competition/posts?cursor=${cursor}${shortCompetitionId ? `&shortCompetitionId=${shortCompetitionId}` : ''}`
    );
  },

  async getSingleShortCompetition(id: number): Promise<ShortCompetition> {
    return await fetch<ShortCompetition>(`/api/short-competition/${id}`);
  }
});
